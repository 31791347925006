var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { pickBy } from 'lodash-es';
import { dispatch, actions } from 'codesandbox-api';
import { preloadedProtocols, getFetchProtocol, } from './dynamic/fetch-protocols';
import dependenciesToQuery from './dependencies-to-query';
import { parseResolutions } from './dynamic/resolutions';
import { resolveDependencyInfo } from './dynamic/resolve-dependency';
import { getDependency as getPrebundledDependency } from './preloaded/fetch-dependencies';
import { mergeDependencies } from './merge-dependency';
import { getSandpackSecret, removeSandpackSecret } from '../sandpack-secret';
let loadedDependencyCombination = null;
let manifest = null;
const PRELOADED_PROTOCOLS = [
    preloadedProtocols.jsdelivr,
    preloadedProtocols.unpkg,
];
/**
 * Depending on the dependency version we decide whether we can load a prebundled bundle (generated
 * in a lambda) or use a dynamic version of fetching the dependency.
 */
function shouldFetchDynamically(depName, depVersion) {
    const fetchProtocol = getFetchProtocol(depName, depVersion);
    return !PRELOADED_PROTOCOLS.includes(fetchProtocol);
}
/**
 * Some dependencies have a space in their version for some reason, this is invalid and we
 * ignore them. This is what yarn does as well.
 */
function removeSpacesFromDependencies(dependencies) {
    const newDeps = {};
    Object.keys(dependencies).forEach(depName => {
        const [version] = dependencies[depName].split(' ');
        newDeps[depName] = version;
    });
    return newDeps;
}
/**
 * Split the dependencies between whether they should be loaded from dynamically or from an endpoint
 * that has the dependency already prebundled.
 */
function splitDependencies(dependencies, forceFetchDynamically) {
    if (forceFetchDynamically) {
        return { dynamicDependencies: dependencies, prebundledDependencies: {} };
    }
    const dynamicDependencies = {};
    const prebundledDependencies = {};
    Object.keys(dependencies).forEach(depName => {
        const version = dependencies[depName];
        if (shouldFetchDynamically(depName, version)) {
            dynamicDependencies[depName] = version;
        }
        else {
            prebundledDependencies[depName] = version;
        }
    });
    return { dynamicDependencies, prebundledDependencies };
}
export function getDependenciesFromSources(dependencies, externals, resolutions, forceFetchDynamically, updateProgress) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const parsedResolutions = parseResolutions(resolutions);
            const remainingDependencies = Object.keys(dependencies);
            const totalDependencies = remainingDependencies.length;
            const depsWithNodeLibs = removeSpacesFromDependencies(Object.assign({ 'node-libs-browser': '2.2.0' }, dependencies));
            const { dynamicDependencies, prebundledDependencies } = splitDependencies(depsWithNodeLibs, forceFetchDynamically);
            const updateLoadScreen = () => {
                const progress = totalDependencies - remainingDependencies.length;
                const total = totalDependencies;
                updateProgress({
                    done: progress,
                    total,
                    remainingDependencies,
                });
            };
            const dynamicPromise = Promise.all(Object.keys(dynamicDependencies).map(depName => resolveDependencyInfo(depName, depsWithNodeLibs[depName], parsedResolutions).finally(() => {
                remainingDependencies.splice(remainingDependencies.indexOf(depName), 1);
                updateLoadScreen();
            })));
            const prebundledPromise = Promise.all(Object.keys(prebundledDependencies).map(depName => getPrebundledDependency(depName, depsWithNodeLibs[depName], externals)
                .then(d => {
                // Unfortunately we've let this through in our system, some dependencies will just be { error: string }.
                // The bug has been fixed, but dependencies have been cached, we have to filter them out and fetch them
                // dynamically.
                // @ts-ignore not possible anymore
                if (d.error) {
                    return resolveDependencyInfo(depName, depsWithNodeLibs[depName], parsedResolutions);
                }
                return d;
            })
                .finally(() => {
                remainingDependencies.splice(remainingDependencies.indexOf(depName), 1);
                updateLoadScreen();
            })));
            const [dynamicLoadedDependencies, prebundledLoadedDependencies,] = yield Promise.all([dynamicPromise, prebundledPromise]);
            return mergeDependencies([
                ...dynamicLoadedDependencies,
                ...prebundledLoadedDependencies,
            ]);
        }
        catch (err) {
            if (getSandpackSecret()) {
                removeSandpackSecret();
            }
            err.message = `Could not fetch dependencies, please try again in a couple seconds: ${err.message}`;
            dispatch(actions.notifications.show(err.message, 'error'));
            throw err;
        }
    });
}
/**
 * This fetches the manifest and dependencies from our packager or dynamic sources
 * @param {*} dependencies
 */
export function loadDependencies(dependencies, externals, updateProgress, { disableExternalConnection = false, resolutions = undefined, } = {}) {
    return __awaiter(this, void 0, void 0, function* () {
        let isNewCombination = false;
        if (Object.keys(dependencies).length !== 0) {
            // We filter out all @types, as they are not of any worth to the bundler
            const dependenciesWithoutTypings = pickBy(dependencies, (val, key) => !(key.includes && key.includes('@types')));
            const depQuery = dependenciesToQuery(dependenciesWithoutTypings);
            if (loadedDependencyCombination !== depQuery) {
                isNewCombination = true;
                const data = yield getDependenciesFromSources(dependenciesWithoutTypings, externals, resolutions, disableExternalConnection, updateProgress);
                // Mark that the last requested url is this
                loadedDependencyCombination = depQuery;
                manifest = data;
            }
        }
        else {
            manifest = null;
        }
        return { manifest, isNewCombination };
    });
}
