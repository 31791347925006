var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _debug from '@codesandbox/common/lib/utils/debug';
import { getAbsoluteDependency } from '@codesandbox/common/lib/utils/dependencies';
import delay from '../../utils/delay';
import dependenciesToQuery, { normalizeVersion, } from '../dependencies-to-query';
const RETRY_COUNT = 60;
const MAX_RETRY_DELAY = 10000;
const debug = _debug('cs:sandbox:packager');
const VERSION = 2;
// eslint-disable-next-line
const DEV_URLS = {
    packager: 'https://xi5p9f7czk.execute-api.eu-west-1.amazonaws.com/dev/packages',
    bucket: 'https://dev-packager-packages.codesandbox.io',
};
// eslint-disable-next-line
const PROD_URLS = {
    packager: 'https://aiwi8rnkp5.execute-api.eu-west-1.amazonaws.com/prod/packages',
    bucket: 'https://prod-packager-packages.codesandbox.io',
};
const URLS = PROD_URLS;
const BUCKET_URL = URLS.bucket;
const PACKAGER_URL = URLS.packager;
function callApi(url, method = 'GET') {
    return fetch(url, {
        method,
    })
        .then((response) => __awaiter(this, void 0, void 0, function* () {
        if (!response.ok) {
            const error = new Error(response.statusText || '' + response.status);
            try {
                // @ts-ignore
                error.response = yield response.text();
            }
            catch (err) {
                console.error(err);
            }
            // @ts-ignore
            error.statusCode = response.status;
            throw error;
        }
        return response;
    }))
        .then(response => response.json());
}
/**
 * Request the packager, if retries > RETRY_COUNT it will throw if something goes wrong
 * otherwise it will retry again with an incremented retry
 *
 * @param {string} query The dependencies to call
 */
function requestPackager(url, method = 'GET', retries = 0) {
    return __awaiter(this, void 0, void 0, function* () {
        // eslint-disable-next-line no-constant-condition
        debug(`Trying to call packager for ${retries} time`);
        try {
            const manifest = yield callApi(url, method);
            return manifest;
        }
        catch (err) {
            console.error({ err });
            // If it's a 403 or network error, we retry the fetch
            if (err.response && err.statusCode !== 403) {
                throw new Error(err.response.error);
            }
            // 403 status code means the bundler is still bundling
            if (retries < RETRY_COUNT) {
                const msDelay = Math.min(MAX_RETRY_DELAY, 1000 * retries + Math.round(Math.random() * 1000));
                console.warn(`Retrying package fetch in ${msDelay}ms`);
                yield delay(msDelay);
                return requestPackager(url, method, retries + 1);
            }
            throw err;
        }
    });
}
const NECESSARY_DEPENDENCIES = ['react', 'react-dom'];
export function getDependency(depName, depVersion, externals) {
    return __awaiter(this, void 0, void 0, function* () {
        let version = depVersion;
        try {
            const { version: absoluteVersion } = yield getAbsoluteDependency(depName, depVersion);
            version = absoluteVersion;
        }
        catch (e) {
            /* Ignore this, not critical */
        }
        const normalizedVersion = normalizeVersion(version);
        const dependencyUrl = dependenciesToQuery({ [depName]: normalizedVersion });
        const fullUrl = `${BUCKET_URL}/v${VERSION}/packages/${depName}/${normalizedVersion}.json`;
        if (externals[depName] && !NECESSARY_DEPENDENCIES.includes(depName)) {
            return {
                contents: {},
                dependency: {
                    name: depName,
                    version: normalizedVersion
                },
                dependencyDependencies: {},
                peerDependencies: {},
                dependencyAliases: {}
            };
        }
        try {
            const bucketManifest = yield callApi(fullUrl);
            return bucketManifest;
        }
        catch (e) {
            // The dep has not been generated yet...
            const packagerRequestUrl = `${PACKAGER_URL}/${dependencyUrl}`;
            yield requestPackager(packagerRequestUrl, 'POST');
            return requestPackager(fullUrl);
        }
    });
}
